.card{
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: var(--color-bg-darker);
    gap: 2vw;
    border-radius: 10px;
    padding: 3vw;
    min-width: 35%;
    margin: 1rem;
}
.card__content{
    display: flex;
    flex-direction: column;
    color: white;
    gap: 1vw;
}
.card_image_conatiner{
    width: 40%;
}
.card__image{
    width: 80%;
}

.cardcompletion__rate{
    width: 100%;
}
.card__percentage{
    width: 40%;
}