@import url('https://fonts.googleapis.com/css2?family=Sora:wght@400;600&display=swap');
/* history.css */
.main-container {
  display: flex;
  width: 360px;
  padding-bottom: 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.card-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.card-f {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none;
  padding: 10px;
  width: 80%;
  margin: auto;
  border-bottom: 1px solid #747474; /* Add a bottom border */
}

.card-text {
  color: #FFFAFA; /* Text color */
  font-family: 'Sora', sans-serif; /* Font family */
  font-size: 12px; /* Font size */
  font-style: normal; /* Font style */
  font-weight: 300; /* Light weight */
  line-height: 150%; /* Line height */
  /* You can adjust these values as needed */
}

.card-image {
  width: 40.5px;
  height: 36px;
  object-fit: cover;
}

.card-content-f {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.text-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 1rem;
}
.cc{
    display: flex;
    align-items: center;
}
.card-time {
  color: #FBECEC;
  font-family: Sora;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
}

.price-container {
  display: flex;
  align-items: center
}

.card-price {
  color: #129976; /* Text color */
  text-align: right; /* Text alignment */
  font-family: 'Sora', sans-serif; /* Font family */
  font-size: 12px; /* Font size */
  font-style: normal; /* Font style */
  font-weight: 400; /* Regular weight */
  line-height: 150%; /* Line height */
}

.arrow-icon {
  font-size: 20px;
  color: #ffffff;
}
.white .card-text{ 
  color: black;
}
.white .card-time{ 
  color: black;
}